import { TypeFilters } from './filters.models';

export class SaveKeyFilters {
  static readonly type = '[Filters] Save key filters';

  constructor(public key: string) {}
}

export class ClearSessionStore {
  static readonly type = '[Filters] Clear SessionStore';
}

export class AddKeyFilters {
  static readonly type = '[Filters] Add key filters';

  constructor(public key: string) {}
}

export class SetPopState {
  static readonly type = '[Filters] set popstate';

  constructor(public popstate: boolean) {}
}

export class ResetFilters {
  static readonly type = '[Filters] reset filters';

  constructor(public key: TypeFilters) {}
}

export class SetFilters {
  static readonly type = '[Filters] SetFilters';

  constructor(
    public key: TypeFilters,
    public page: number,
  ) {}
}

export class RemoveFiltersFromStore {
  static readonly type = '[Filters] remove filters';

  constructor(public prefix: string) {}
}
